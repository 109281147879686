// Font Family
@import url("https://fonts.googleapis.com/css2?family=Lilita+One:wght@400;500;600;700;800&display=swap");

// Colors ---------------
// ARANCIONE -> ff9301
$primary-color: black;
$secundary-color: #68c268;
$third-color: #68c26899;


// Responsive Variables
$xxl: '(min-width: 1600px)';
$xl: '(min-width: 1200px) and (max-width: 1599px)';
$lg: '(min-width: 992px) and (max-width: 1199px)';
$md: '(min-width: 768px) and (max-width: 991px)';
$sm: '(max-width: 767px)';
$lsm: '(min-width: 576px) and (max-width: 767px)';
$xsm: '(max-width: 575px)';
$tiny: '(min-width: 414px) and (max-width: 575px)';
$xtiny: '(max-width: 413px)';



//GESTIONE Responsive CARD
$card1: '(min-width: 0px) and (max-width: 1545px)';
$descrizione1: '(min-width: 1132px) and (max-width: 1325px)';
$descrizione2: '(min-width: 885px) and (max-width: 1132px)';
$descrizione3: '(min-width: 885px) and (max-width: 1100px)';
$descrizione4: '(min-width: 670px) and (max-width: 885px)';
$descrizione5: '(min-width: 500px) and (max-width: 670px)';
$descrizione6: '(min-width: 376px) and (max-width: 500px)';
$descrizione7: '(min-width: 0px) and (max-width: 375px)';

$emoji0: '(min-width: 770px) and (max-width: 900px)';
$emoji1: '(min-width: 500px) and (max-width: 770px)';
$emoji2: '(min-width: 0px) and (max-width: 500px)';



:export {
  primaryColor: $primary-color;
  backgroundColor: $secundary-color;
}