
        @keyframes spin {
            from {
              transform: rotate(0);
            }
            to {
              transform: rotate(360deg);
            }
          }
          
          .miracolo {
            animation: spin 2s linear infinite;
          }




        .scroll {
          width: 60px;
          height: 60px;
          border: 2px solid #fff;
          border-radius: 50%;
          position: fixed;
          bottom: 0;
          right: 0;
          z-index: 100;
          margin-bottom: 15px;
          margin-right: 10px;
          -webkit-animation: down 1.5s infinite;

          &::before {
            content: '';
            position: absolute;
            top: 15px;
            left: 18px;
            width: 18px;
            height: 18px;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transform: rotate(-45deg);
          }
        }

        @keyframes down {
          0% {
            transform: translate(0);
          }
          20% {
            transform: translateY(15px);
          }
          40% {
            transform: translate(0);
          }
        }

        @-webkit-keyframes down {
          0% {
            transform: translate(0);
          }
          20% {
            transform: translateY(15px);
          }
          40% {
            transform: translate(0);
          }
        }