.faq-section {
    padding-bottom: 0 !important;
    padding: 120px 0px;


.accordion-button{
    background-color:rgb(27, 129, 58);
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 4px 10px 12px rgba(0, 0, 0, 0.3);
  }
  .collapsed {
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  }

  .title {
    margin-bottom: 35px;
    @media #{$sm} {
        margin-bottom:25px;
    }
}


.title h3 {
    color: #fff;
    display: inline-block;
    font-size: 30px;
    font-weight: 600;
    margin: 0;
    position: relative;
    vertical-align: top;
    z-index: 1;
    color: white;
}

.title h3:after {
    animation: slide-both-side 2s infinite both;
    background: $secundary-color;
    border-radius: 50%;
    content: "";
    height: 45px;
    left: -15px;
    position: absolute;
    top: -8px;
    width: 45px;
    z-index: -1;
    background: $secundary-color;
}

@-webkit-keyframes slide-both-side {
    0% {
        -webkit-transform: translate(-5px);
        transform: translateY(-5px)
    }

    50% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px)
    }

    to {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px)
    }
}

@keyframes slide-both-side {
    0% {
        -webkit-transform: translate(-5px);
        transform: translateY(-5px)
    }

    50% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px)
    }

    to {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px)
    }
}
    @media(max-width: 991px) {
        .title h3:after {
            left:0;
            top: -4px
        }
    }
.accordion-body {
    color: white;
}



.rc-accordion-toggle {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    justify-content: space-between;
    background-color: #ebedf094;
    transition: 0.3s;
}

.rc-accordion-toggle.active {
    background-color: #68c268;
}

.rc-accordion-toggle.active .rc-accordion-icon {
    transform: rotate(180deg);
    color: #fff;
}

.rc-accordion-card {
    border: 1px solid #49d64975;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow: hidden;
}

.rc-accordion-card:last-child {
    margin-bottom: 0;
}

.rc-accordion-title {
    font-weight: 500;
    font-size: 14px;
    position: relative;
    margin-bottom: 0;
    color: white;
    transition: 0.3s;
}

.rc-accordion-toggle.active .rc-accordion-title {
    color: #fff;
}

.rc-accordion-icon {
    position: relative;
    top: 2px;
    color: white;
    transition: 0.35s;
    font-size: 12px;
}

.rc-accordion-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 15px;
}

.rc-accordion-body p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff;
}

.rc-collapse {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

.rc-collapse.show {
    height: auto;
}

}