.card-service-section {
	font-family: "Montserrat", sans-serif !important;
	background: $primary-color;
	background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(160, 160, 160) 100%);
	height: auto;
	.card {
		will-change: transform, opacity;

		min-height: 380px;
		max-height: 380px;
		border-radius: 10px;
		overflow: hidden;
		padding: 40px;
		position: relative;
		z-index: 1;
		background: #000000bd;
		cursor: pointer;
		border-color: #8080804f;
	}

	.card2 {
		border-radius: 10px;
		overflow: hidden;
		padding: 40px;
		position: relative;
		z-index: 1;
		background: #000000bd;
		border-color: #8080804f;

		background-blend-mode: color;

		//background-color: rgba(0 ,0 ,0 ,0.65);
		background-repeat: repeat;
		background-size: cover;
		background-position: 50%;
	}

	.card:before {
		background: #0b0b13;
		border-radius: 0;
		left: -220px;
		top: -220px;
		z-index: -1
	}

	.card:after,.card:before {
		content: "";
		height: 200px;
		position: absolute;
		transition: .5s ease-in-out;
		width: 200px
	}

	.card:after {
		background: $secundary-color;
		border-radius: 50%;
		left: -40px;
		top: -72px;
		z-index: -2
	}

	.card .icon {
		color: #fff;
		font-size: 40px;
		line-height: 60px
	}

	.card .card-content {
		padding-top: 50px;

		p{
			font-size: 13px;
		}
	}


	.card h5 {
		margin-bottom: 15px;
		transition: .5s ease-in-out;
		color: white;
		font-family: "Lilita One";
		text-transform: uppercase;
	}

	.card h6 {
		margin-bottom: 15px;
		transition: .5s ease-in-out;
		color: white;
		font-family: "Lilita One";
		text-transform: uppercase;
		margin-top: 15px;
		font-size: 13px;
	}


	.card p {
		margin: 0;
		transition: .5s ease-in-out;
		color:hsla(0,0%,100%,.8);
		line-height: 17.5px !important;
	}

	.card:hover,.card:hover h5 {
		color: #fff
	}

	.card:hover:before {
		border-radius: 50%;
		left: -40px;
		top: -72px
	}

	.card:hover:after {
		border-radius: 0;
		content: "";
		height: 120%;
		left: -10%;
		top: -10%;
		transition: all .55s cubic-bezier(.39,.58,.57,1);
		width: 120%
	}
	.service-card{
		.sfondo-svg{
			background-size: cover;
			background-repeat: no-repeat;
			height: 100%;
			min-height: 100vh;
		}
	}
	.all-card{
		padding-top: 15vh;
	}






	.single-cardv3{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-wrap: nowrap;
		color:white;
		font-family: "Open Sans", sans-serif;
		h1{
			@media #{$md} {
				font-size: 1.6rem;
			}
			@media #{$lsm} {
				font-size: 1.6rem;
			}

			@media #{$tiny} {
				font-size: 1.6rem;
			}
			@media #{$xtiny} {
				font-size: 1.6rem;
			}
		}

		.long_description{
			font-family: "Montserrat", sans-serif !important;
			display: flex;
			flex-direction: column;


			.first_section{
				text-align: center;
				padding-right: 15vh;
				padding-left: 15vh;
				@media #{$md} {
					padding-right: 0vh;
					padding-left: 0vh;
				}
				@media #{$lsm} {
					padding-right: 0vh;
					padding-left: 0vh;
				}

				@media #{$tiny} {
					padding-right: 0vh;
					padding-left: 0vh;
				}
				@media #{$xtiny} {
					padding-right: 0vh;
					padding-left: 0vh;
				}
				h3{
					@media #{$md} {
						font-size: 1.1rem;
					}

					@media #{$lsm} {
						font-size: 1.1rem;
					}
					@media #{$tiny} {
						font-size: 1.1rem;
					}
					@media #{$xtiny} {
						font-size: 1.1rem;
					}

				}
			}
			.central_section{
				text-align: justify;
				display: flex;
				padding-bottom: 2vh;
				padding-right: 15vh;
				padding-left: 15vh;
				@media #{$md} {
					padding-right: 0vh;
					padding-left: 0vh;
					text-align: initial;
				}

				@media #{$lsm} {
					padding-right: 0vh;
					padding-left: 0vh;
					text-align: initial;
				}
				@media #{$tiny} {
					padding-right: 0vh;
					padding-left: 0vh;
					text-align: initial;
				}
				@media #{$xtiny} {
					padding-right: 0vh;
					padding-left: 0vh;
					text-align: initial;
				}
				h5{
					@media #{$md} {
						font-size: 1rem;
					}

					@media #{$lsm} {
						font-size: 1rem;
					}
					@media #{$tiny} {
						font-size: 1rem;
					}
					@media #{$xtiny} {
						font-size: 1rem;
					}

				}

			}
			.last_section{
				text-align: justify;
				padding-right: 15vh;
				padding-left: 15vh;
				@media #{$md} {
					padding-right: 0vh;
					padding-left: 0vh;
					text-align: initial;
				}

				@media #{$lsm} {
					padding-right: 0vh;
					padding-left: 0vh;
					text-align: initial;
				}
				@media #{$tiny} {
					padding-right: 0vh;
					padding-left: 0vh;
					text-align: initial;
				}
				@media #{$xtiny} {
					padding-right: 0vh;
					padding-left: 0vh;
					text-align: initial;
				}
				h5{
					@media #{$md} {
						font-size: 1rem;
					}

					@media #{$lsm} {
						font-size: 1rem;
					}
					@media #{$tiny} {
						font-size: 1rem;
					}
					@media #{$xtiny} {
						font-size: 1rem;
					}

				}
			}
			.group-icon {
				display: flex;
				align-items: center;
				justify-content: center;
				padding-top: 5vh;
				padding-bottom: 5vh;
				flex-wrap: wrap;
				@media #{$md} {
					padding-top: 1vh;
					padding-bottom: 1vh;
				}

				@media #{$lsm} {
					padding-top: 1vh;
					padding-bottom: 1vh;
				}
				@media #{$tiny} {
					padding-top: 1vh;
					padding-bottom: 1vh;
				}
				@media #{$xtiny} {
					padding-top: 1vh;
					padding-bottom: 1vh;
				}
				.img-custom {

					@media #{$md} {
						width: 20% !important;
					}

					@media #{$lsm} {
						width: 20% !important;
					}
					@media #{$tiny} {
						width: 20% !important;
					}
					@media #{$xtiny} {
						width: 20% !important;
					}


				}
			}
		}

	}



	.title {
		margin-bottom: 35px;
		@media #{$sm} {
			margin-bottom:25px;
		}
	}

	.title h3 {
		color: #fff;
		display: inline-block;
		font-size: 30px;
		font-weight: 600;
		margin: 0;
		position: relative;
		vertical-align: top;
		z-index: 1;
		color: white;
	}

	.title h3:after {
		animation: slide-both-side 2s infinite both;
		background: $secundary-color;
		border-radius: 50%;
		content: "";
		height: 45px;
		left: -15px;
		position: absolute;
		top: -8px;
		width: 45px;
		z-index: -1;
		background: $secundary-color;
	}

	.title h1 {
		color: #fff;
		display: inline-block;

		font-weight: 600;
		margin: 0;
		position: relative;
		vertical-align: top;
		z-index: 1;
		color: white;
	}

	.title h1:after {
		animation: slide-both-side 2s infinite both;
		background: $secundary-color;
		border-radius: 50%;
		content: "";
		height: 45px;
		left: -15px;
		position: absolute;
		top: -8px;
		width: 45px;
		z-index: -1;
		background: $secundary-color;
	}

	@-webkit-keyframes slide-both-side {
		0% {
			-webkit-transform: translate(-5px);
			transform: translateY(-5px)
		}

		50% {
			-webkit-transform: translateY(5px);
			transform: translateY(5px)
		}

		to {
			-webkit-transform: translateY(-5px);
			transform: translateY(-5px)
		}
	}

	@keyframes slide-both-side {
		0% {
			-webkit-transform: translate(-5px);
			transform: translateY(-5px)
		}

		50% {
			-webkit-transform: translateY(5px);
			transform: translateY(5px)
		}

		to {
			-webkit-transform: translateY(-5px);
			transform: translateY(-5px)
		}
	}

	@media(max-width: 991px) {
		.title h3:after {
			left:0;
			top: -4px
		}
	}



}



