.product-profile-section{
  display: flex;
  justify-content: flex-start;
  .service-card{
    width: 100%;
  }
}
.profile-page-area{

  min-height: 85vh;
  background-color: #d9d9d9;
  position: relative;
  padding: 60px 0px 60px;
  padding-right: 5vw;
  padding-left: 5vw;
  margin-top: 4vh;
  h1 {
    font-size: 40px;
    line-height: 0.8em;
    color: rgba(255,255,255,0.2);
  }
  .made-with {
    background: #68c268;
    color: #fff;
    display: block;
    font-size: 12px;
    line-height: 1em;
    margin: 0;
    padding: 5px 110px;
    position: fixed;
    top: 20px;
    right: -100px;
    text-align: center;
    text-decoration: none;
    transform: rotate(45deg);
  }
  .documentation {
    color: #68c268;
  }
  button:focus,
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }
  .tabs {
    display: block;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    overflow: hidden;
  }
  .tabs [class^="tab"] label,
  .tabs [class*=" tab"] label {
    color: black;
    cursor: pointer;
    display: block;
    font-size: 1.2em;
    font-weight: 300;
    line-height: 1em;
    padding: .5rem 0;
    text-align: center;
    font-family: "Lilita One";
  }
  .tabs [class^="tab"] [type="radio"],
  .tabs [class*=" tab"] [type="radio"] {
    border-bottom: 1px solid rgba(239, 237, 239, 0.5);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .tabs [class^="tab"] [type="radio"]:hover, .tabs [class^="tab"] [type="radio"]:focus,
  .tabs [class*=" tab"] [type="radio"]:hover,
  .tabs [class*=" tab"] [type="radio"]:focus {
    border-bottom: 7px solid #68c268;
  }
  .tabs [class^="tab"] [type="radio"]:checked,
  .tabs [class*=" tab"] [type="radio"]:checked {
    border-bottom: 7px solid #68c268;
  }
  .tabs [class^="tab"] [type="radio"]:checked + div,
  .tabs [class*=" tab"] [type="radio"]:checked + div {
    opacity: 1;
  }
  .tabs [class^="tab"] [type="radio"] + div,
  .tabs [class*=" tab"] [type="radio"] + div {
    display: block;
    opacity: 0;
    padding: 2rem 0;
    width: 90%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .tabs .tab-2 {
    width: 50%;
  }
  .tabs .tab-2 [type="radio"] + div {
    width: 200%;
    margin-left: 200%;
  }
  .tabs .tab-2 [type="radio"]:checked + div {
    margin-left: 0;
  }
  .tabs .tab-2:last-child [type="radio"] + div {
    margin-left: 100%;
  }
  .tabs .tab-2:last-child [type="radio"]:checked + div {
    margin-left: -100%;
  }
  card-container{
    position: relative;
  }

  .card-container .card{
    position: relative;
    width: 100%;
    min-height: 200px;
    max-height: 200px;
    background: #232323;
    border-radius: 20px;
    overflow: hidden;
  }

  .card-container .card:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #68c268;
    clip-path: circle(150px at 80% 20%);
    transition: 0.5s ease-in-out;
  }

  .card-container .card:hover:before{
    clip-path: circle(300px at 80% -20%);
  }

  .card-container .card:after{
    content: 'Protear';
    position: absolute;
    top: 30%;
    left: -20%;
    font-size: 12em;
    font-weight: 800;
    font-style: italic;
    color: rgba(129, 255, 113, 0.05)
  }

  .card-container .card .imgBx{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10000;
    width: 100%;
    height: 220px;
    transition: 0.5s;
  }

  .card-container .card:hover .imgBx{
    top: 0%;
    transform: translateY(0%);

  }

  .card-container .card .imgBx img{
    position: absolute;

  }

  .card-container .card .contentBx{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    text-align: center;
    transition: 1s;
    z-index: 10;
  }

  .card-container .card:hover .contentBx{
    height: 210px;
  }

  .card-container .card .contentBx h2{
    position: relative;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
    margin: 0;
  }

  .card-container .card .contentBx .size, .card-container .card .contentBx .color {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    transition: 0.5s;opacity: 0;
    visibility: hidden;
    padding-top: 0;
    padding-bottom: 0;
  }

  .card-container .card:hover .contentBx .size{
    opacity: 1;
    visibility: visible;
    transition-delay: 0.5s;
  }

  .card-container .card:hover .contentBx .color{
    opacity: 1;
    visibility: visible;
    transition-delay: 0.6s;
  }

  .card-container .card .contentBx .size h3, .card-container .card .contentBx .color h3{
    color: #fff;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-right: 10px;
    font-family: "Lilita One";
  }

  .card-container .card .contentBx .size span{
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 26px;
    font-size: 14px;
    display: inline-block;
    color: #111;
    background: #fff;
    margin: 0 5px;
    transition: 0.5s;
    color: #111;
    border-radius: 4px;
    cursor: pointer;
  }

  .card-container .card .contentBx .size span:hover{
    background: #68c268;
  }

  .card-container .card .contentBx .color span{
    width: 20px;
    height: 20px;
    background: #ff0;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }

  .card-container .card .contentBx .color span:nth-child(2){
    background: #9bdc28;
  }

  .card-container .card .contentBx .color span:nth-child(3){
    background: #03a9f4;
  }

  .card-container .card .contentBx .color span:nth-child(4){
    background: #e91e63;
  }

  .card-container .card .contentBx a{
    display: inline-block;
    padding: 10px 20px;
    background: #fff;
    border-radius: 4px;
    margin-top: 10px;
    text-decoration: none;
    font-weight: 600;
    color: #111;
    opacity: 0;
    transform: translateY(50px);
    transition: 0.5s;
    margin-top: 0;
    font-family: 'Lilita One';
    font-size: 13px;
  }

  .card-container .card:hover .contentBx a{
    opacity: 1;
    transform: translateY(0px);
    transition-delay: 0.75s;

  }
  .box {
    background: white;
    border-radius: 20px;
    /*display: grid;*/
   /* grid-template-columns: 64px 1fr;*/
    position: relative;
    width: 100%;
    height: 100%;
  }

  .box-icon {
    display: grid;
    place-items: center;
  }

  .box-label {
    height: 64px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    font-size: 22px;
    letter-spacing: 0.125em;
    font-family: "Lilita One";
  }

  .box-title {
    white-space: nowrap;
    display: flex;
    align-items: center;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    font-size: 24px;
    padding-top: 16px;
  }

  .box-image {

    border-radius: 18px 0 18px 0;
    overflow: hidden;

    img {
      width: 100%;
      display: block;
    }
  }

  .studio-button {
    position: absolute;
    bottom: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    background: #68c268;
    color: white;
    padding: 1px 10px;
    border-radius: 50px;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
    transition: 0.35s ease all;
    overflow: hidden;
    max-width: 40px;
  }

  .studio-button-icon {
    position: relative;
    top: 1px;
  }

  .studio-button-label {
    cursor: pointer;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 0 8px;
    opacity: 0;
    transform: translateX(10px);
    transition: 0.25s ease all;
    font-family: "Lilita One";
  }

  .box:hover {
    .studio-button {
      max-width: 100%;
    }
    .studio-button-label {
      opacity: 1;
      transform: translateX(0);
      transition: 0.25s 0.1s ease-in opacity, 0.15s 0.1s cubic-bezier(.175, .885, .32, 1.275) transform;
    }
  }
  .background {
    padding: 0 25px 25px;
    position: relative;
    width: 100%;
  }



  @media (min-width: 1100px) {
    .background {
      padding: 0 0 25px;
    }
  }

  .container {

    width: 100%;
    padding:0 !important;
    margin:0 !important;
    max-width: 100%;
  }

  .panel {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 25px;
    position: relative;
    width: 100%;
    z-index: 10;
  }

  .pricing-table {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1100px) {
    .pricing-table {
      flex-direction: row;
    }
  }

  .pricing-table * {
    text-align: center;
    text-transform: uppercase;
  }

  .pricing-plan {
    border-bottom: 1px solid #e1f1ff;
    padding: 25px;
  }

  .pricing-plan:last-child {
    border-bottom: none;
  }

  @media (min-width: 1100px) {
    .pricing-plan {
      border-bottom: none;
      border-right: 1px solid #e1f1ff;
      flex-basis: 100%;
      padding: 25px 50px;
    }

    .pricing-plan:last-child {
      border-right: none;
    }
  }

  .pricing-img {
    margin-bottom: 25px;
    max-width: 100%;
  }

  .pricing-header {
    font-family: "Lilita One";

    color: black;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .pricing-features {
    color: #68c268;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 50px 0 25px;
  }

  .pricing-features-item {
    border-top: 1px solid #e1f1ff;
    font-size: 12px;
    line-height: 1.5;
    padding: 15px 0;
  }

  .pricing-features-item:last-child {
    border-bottom: 1px solid #e1f1ff;
  }

  .pricing-price {
    color: #68c268;
    display: block;
    font-size: 32px;
    font-weight: 700;
  }

  .pricing-button {
    border: 1px solid #9dd1ff;
    border-radius: 10px;
    color: #348EFE;
    display: inline-block;
    margin: 25px 0;
    padding: 15px 35px;
    text-decoration: none;
    transition: all 150ms ease-in-out;
  }

  .pricing-button:hover,
  .pricing-button:focus {
    background-color: #e1f1ff;
  }

  .pricing-button.is-featured {
    background-color: #48aaff;
    color: #fff;
  }

  .pricing-button.is-featured:hover,
  .pricing-button.is-featured:active {
    background-color: #269aff;
  }

  .tabset > input[type="radio"] {
    position: absolute;
    left: -200vw;
  }

  .tabset .tab-panel {
    display: none;
  }

  .tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
  .tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
  .tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
  .tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
  .tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
  .tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
    display: block;
  }

  /*
   Styling
  */
  body {
    font: 16px/1.5em "Overpass", "Open Sans", Helvetica, sans-serif;
    color: #333;
    font-weight: 300;
  }

  .tabset > label {
    position: relative;
    display: inline-block;
    padding: 15px 15px 25px;
    border: 1px solid transparent;
    border-bottom: 0;
    cursor: pointer;
    /*font-weight: 600;*/
    font-family: "Lilita One";
    color: black;
  }

  .tabset > label::after {
    content: "";
    position: absolute;
    left: 15px;
    bottom: 10px;
    width: 22px;
    height: 4px;
    background: #8d8d8d;


  }

  input:focus-visible + label {
    outline: 2px solid rgba(0,102,204,1);
    border-radius: 3px;
  }

  .tabset > label:hover,
  .tabset > input:focus + label,
  .tabset > input:checked + label {
    color: #68c268;
  }

  .tabset > label:hover::after,
  .tabset > input:focus + label::after,
  .tabset > input:checked + label::after {
    background: #68c268;
  }

  .tabset > input:checked + label {
    border-color: #ccc;
    border-bottom: 1px solid #fff;
    margin-bottom: -1px;
  }

  .tab-panel {
    /*padding: 30px 0;*/
    /*border-top: 1px solid #ccc;*/
  }

  /*
   Demo purposes only
  */
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  body {
    padding: 30px;
  }

  .tabset {

  }


  .pcss3t {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    font-size: 0;
    text-align: left;
  }
  .pcss3t > input {
    position: absolute;
    left: -9999px;
  }
  .pcss3t > label {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    cursor: pointer;
    transition: all 0.1s;
    -o-transition: all 0.1s;
    -ms-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -webkit-transition: all 0.1s;
  }
  .pcss3t > label i {
    display: block;
    float: left;
    margin: 16px 8px 0 -2px;
    padding: 0;
    border: 0;
    outline: none;
    font-family: FontAwesome;
    font-style: normal;
    font-size: 17px;
  }
  .pcss3t > input:checked + label {
    cursor: default;
  }
  .pcss3t > ul {
    list-style: none;
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    font-size: 13px;
  }
  .pcss3t > ul > li {
    position: absolute;
    width: 100%;
    overflow: auto;
    padding: 30px 40px 40px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    opacity: 0;
    transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .pcss3t > .tab-content-first:checked ~ ul .tab-content-first,
  .pcss3t > .tab-content-2:checked ~ ul .tab-content-2,
  .pcss3t > .tab-content-3:checked ~ ul .tab-content-3,
  .pcss3t > .tab-content-4:checked ~ ul .tab-content-4,
  .pcss3t > .tab-content-5:checked ~ ul .tab-content-5,
  .pcss3t > .tab-content-6:checked ~ ul .tab-content-6,
  .pcss3t > .tab-content-7:checked ~ ul .tab-content-7,
  .pcss3t > .tab-content-8:checked ~ ul .tab-content-8,
  .pcss3t > .tab-content-9:checked ~ ul .tab-content-9,
  .pcss3t > .tab-content-last:checked ~ ul .tab-content-last {
    z-index: 1;
    top: 0;
    left: 0;
    opacity: 1;
    -webkit-transform: scale(1,1);
    -webkit-transform: rotate(0deg);
  }


  /*----------------------------------------------------------------------------*/
  /*                                 EXTENSIONS                                 */
  /*----------------------------------------------------------------------------*/

  /**/
  /* auto height */
  /**/
  .pcss3t-height-auto > ul {
    height: auto !important;
  }
  .pcss3t-height-auto > ul > li {
    position: static;
    display: none;
    height: auto !important;
  }
  .pcss3t-height-auto > .tab-content-first:checked ~ ul .tab-content-first,
  .pcss3t-height-auto > .tab-content-2:checked ~ ul .tab-content-2,
  .pcss3t-height-auto > .tab-content-3:checked ~ ul .tab-content-3,
  .pcss3t-height-auto > .tab-content-4:checked ~ ul .tab-content-4,
  .pcss3t-height-auto > .tab-content-5:checked ~ ul .tab-content-5,
  .pcss3t-height-auto > .tab-content-last:checked ~ ul .tab-content-last {
    display: block;
  }


  /**/
  /* grid */
  /**/
  .pcss3t .grid-row {
    margin-top: 20px;
  }
  .pcss3t .grid-row:after {
    content: '';
    display: table;
    clear: both;
  }
  .pcss3t .grid-row:first-child {
    margin-top: 0;
  }
  .pcss3t .grid-col {
    display: block;
    float: left;
    margin-left: 2%;
  }
  .pcss3t .grid-col:first-child {
    margin-left: 0;
  }
  .pcss3t .grid-col .inner {
    padding: 10px 0;
    border-radius: 5px;
    background: #f2f2f2;
    text-align: center;
  }
  .pcss3t .grid-col-1 {
    width: 15%;
  }
  .pcss3t .grid-col-2 {
    width: 32%;
  }
  .pcss3t .grid-col-3 {
    width: 49%;
  }
  .pcss3t .grid-col-4 {
    width: 66%;
  }
  .pcss3t .grid-col-5 {
    width: 83%;
  }
  .pcss3t .grid-col-offset-1 {
    margin-left: 19%;
  }
  .pcss3t .grid-col-offset-1:first-child  {
    margin-left: 17%;
  }
  .pcss3t .grid-col-offset-2 {
    margin-left: 36%;
  }
  .pcss3t .grid-col-offset-2:first-child {
    margin-left: 34%;
  }
  .pcss3t .grid-col-offset-3 {
    margin-left: 53%;
  }
  .pcss3t .grid-col-offset-3:first-child {
    margin-left: 51%;
  }
  .pcss3t .grid-col-offset-4 {
    margin-left: 70%;
  }
  .pcss3t .grid-col-offset-4:first-child {
    margin-left: 68%;
  }
  .pcss3t .grid-col-offset-5:first-child {
    margin-left: 85%;
  }


  /**/
  /* typography */
  /**/
  .pcss3t .typography {
    color: #666;
  }
  .pcss3t .typography h1,
  .pcss3t .typography h2,
  .pcss3t .typography h3,
  .pcss3t .typography h4,
  .pcss3t .typography h5,
  .pcss3t .typography h6 {
    margin: 40px 0 0 0;
    padding: 0;
    font-family: Gabriela, Georgia, serif;
    text-align: left;
    color: #333;
  }
  .pcss3t .typography h1 {
    font-size: 40px;
    line-height: 60px;
    text-shadow: 3px 3px rgba(0,0,0,0.1);
  }
  .pcss3t .typography h2 {
    font-size: 32px;
    line-height: 48px;
    text-shadow: 2px 2px rgba(0,0,0,0.1);
  }
  .pcss3t .typography h3 {
    font-size: 26px;
    line-height: 38px;
    text-shadow: 1px 1px rgba(0,0,0,0.1);
  }
  .pcss3t .typography h4 {
    font-size: 20px;
    line-height: 30px;
  }
  .pcss3t .typography h5 {
    font-size: 15px;
    line-height: 23px;
    text-transform: uppercase;
  }
  .pcss3t .typography h6 {
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .pcss3t .typography p {
    margin: 20px 0 0 0;
    padding: 0;
    line-height: 20px;
    text-align: left;
  }
  .pcss3t .typography ul,
  .pcss3t .typography ol {
    list-style: none;
    margin: 20px 0 0 0;
    padding: 0;
  }
  .pcss3t .typography li {
    position: relative;
    margin-top: 5px;
    padding-left: 20px;
  }
  .pcss3t .typography li ul,
  .pcss3t .typography li ol {
    margin-top: 5px;
  }
  .pcss3t .typography ul li:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 6px;
    height: 4px;
    background: #404040;
  }
  .pcss3t .typography ol {
    counter-reset: list1;
  }
  .pcss3t .typography ol > li:before {
    counter-increment:list1;
    content: counter(list1)'.';
    position: absolute;
    top: 0;
    left: 0;
  }
  .pcss3t .typography a {
    text-decoration: underline;
    color: #1889e6;
  }
  .pcss3t .typography a:hover {
    text-decoration: none;
  }
  .pcss3t .typography .pic {
    padding: 4px;
    border: 1px dotted #ccc;
  }
  .pcss3t .typography .pic img {
    display: block;
  }
  .pcss3t .typography .pic-right {
    float: right;
    margin: 0 0 10px 20px;
  }
  .pcss3t .typography .link {
    text-decoration: underline;
    color: #1889e6;
    cursor: pointer;
  }
  .pcss3t .typography .link:hover {
    text-decoration: none;
  }
  .pcss3t .typography h1:first-child,
  .pcss3t .typography h2:first-child,
  .pcss3t .typography h3:first-child,
  .pcss3t .typography h4:first-child,
  .pcss3t .typography h5:first-child,
  .pcss3t .typography h6:first-child,
  .pcss3t .typography p:first-child {
    margin-top: 0;
  }
  .pcss3t .typography .text-center {
    text-align: center;
  }
  .pcss3t .typography .text-right {
    text-align: right;
  }


  /**/
  /* steps */
  /**/
  .pcss3t-steps > label {
    cursor: default;
  }


  /**/
  /* animation effects */
  /**/
  .pcss3t-effect-scale > ul > li {
    -webkit-transform: scale(0.1,0.1);
  }
  .pcss3t-effect-rotate  > ul > li {
    -webkit-transform: rotate(180deg);
  }
  .pcss3t-effect-slide-top > ul > li {
    top: -40px;
  }
  .pcss3t-effect-slide-right > ul > li {
    left: 80px;
  }
  .pcss3t-effect-slide-bottom > ul > li {
    top: 40px;
  }
  .pcss3t-effect-slide-left > ul > li {
    left: -80px;
  }



  /*----------------------------------------------------------------------------*/
  /*                                   LAYOUTS                                  */
  /*----------------------------------------------------------------------------*/

  /**/
  /* top right */
  /**/
  .pcss3t-layout-top-right {
    text-align: right;
  }


  /**/
  /* top center */
  /**/
  .pcss3t-layout-top-center {
    text-align: center;
  }


  /**/
  /* top combi */
  /**/
  .pcss3t > .right {
    float: right;
  }



  /*----------------------------------------------------------------------------*/
  /*                                    ICONS                                   */
  /*----------------------------------------------------------------------------*/

  /**/
  /* icons positions */
  /**/
  .pcss3t-icons-top > label {
    text-align: center;
  }
  .pcss3t-icons-top > label i {
    float: none;
    margin: 0 auto -10px;
    padding-top: 17px;
    font-size: 23px;
    line-height: 23px;
    text-align: center;
  }
  .pcss3t-icons-right > label i {
    float: right;
    margin: 0 -2px 0 8px;
  }
  .pcss3t-icons-bottom > label {
    text-align: center;
  }
  .pcss3t-icons-bottom > label i {
    float: none;
    margin: -10px auto 0;
    padding-bottom: 17px;
    font-size: 23px;
    line-height: 23px;
    text-align: center;
  }
  .pcss3t-icons-only > label i {
    float: none;
    margin: 0 auto;
    font-size: 23px;
  }
  @media screen and (max-width: 980px) {

  }


  /**/
  /* phone */
  /**/
  @media screen and (max-width: 767px) {
    .pcss3t > label {
      display: block;
    }
    .pcss3t > .right {
      float: none;
    }
  }
  .pcss3t > label {
    padding: 0 20px;
    background: #e5e5e5;
    font-size: 13px;
    line-height: 49px;
  }
  .pcss3t > label:hover {
    background: #f2f2f2;
  }
  .pcss3t > input:checked + label {
    background: #fff;
  }
  .pcss3t > ul {
    background: #fff;
    text-align: left;
  }
  .pcss3t-steps > label:hover {
    background: #e5e5e5;
  }


  /**/
  /* theme 1 */
  /**/
  .pcss3t-theme-1 > label {
    margin: 0 5px 5px 0;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 2px rgba(0,0,0,0.2);
    color: #808080;
    opacity: 0.8;
  }
  .pcss3t-theme-1 > label:hover {
    background: #fff;
    opacity: 1;
  }
  .pcss3t-theme-1 > input:checked + label {
    margin-bottom: 0;
    padding-bottom: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    color: #2b82d9;
    opacity: 1;
  }
  .pcss3t-theme-1 > ul {
    border-radius: 5px;
    box-shadow: 0 3px rgba(0,0,0,0.2);
  }
  .pcss3t-theme-1 > .tab-content-first:checked ~ ul {
    border-top-left-radius: 0;
  }
  @media screen and (max-width: 767px) {
    .pcss3t-theme-1 > label {
      margin-right: 0;
    }
    .pcss3t-theme-1 > input:checked + label {
      margin-bottom: 5px;
      padding-bottom: 0;
      border-radius: 5px;
    }
    .pcss3t-theme-1 > .tab-content-first:checked ~ ul {
      border-top-left-radius: 5px;
    }
  }


  /**/
  /* theme 2 */
  /**/
  .pcss3t-theme-2 {
    padding: 5px;
    background: rgba(0,0,0,0.2);
  }
  .pcss3t-theme-2 > label {
    margin-right: 0;
    margin-bottom: 0;
    background: none;
    border-radius: 0;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
    color: #fff;
    opacity: 1;
  }
  .pcss3t-theme-2 > label:hover {
    background: rgba(255,255,255,0.2);
  }
  .pcss3t-theme-2 > input:checked + label {
    padding-bottom: 0;
    background: #fff;
    background: linear-gradient(to bottom, #e5e5e5 0%, #ffffff 100%);
    background: -o-linear-gradient(top, #e5e5e5 0%, #ffffff 100%);
    background: -ms-linear-gradient(top, #e5e5e5 0%, #ffffff 100%);
    background: -moz-linear-gradient(top, #e5e5e5 0%, #ffffff 100%);
    background: -webkit-linear-gradient(top, #e5e5e5 0%, #ffffff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e5e5e5', endColorstr='#ffffff', GradientType=0);
    text-shadow: 1px 1px 1px rgba(255,255,255,0.5);
    color: #822bd9;
  }
  .pcss3t-theme-2 > ul {
    margin: 0 -5px -5px;
    border-radius: 0;
    box-shadow: none;
  }
  @media screen and (max-width: 767px) {
    .pcss3t-theme-2 > ul {
      margin-top: 5px;
    }
  }


  /**/
  /* theme 3 */
  /**/
  .pcss3t-theme-3 {
    background: rgba(0,0,0,0.8);
  }
  .pcss3t-theme-3 > label {
    background: none;
    border-right: 1px dotted rgba(255,255,255,0.5);
    text-align: center;
    color: #fff;
    opacity: 0.6;
  }
  .pcss3t-theme-3 > label:hover {
    background: none;
    color: #d9d92b;
    opacity: 0.8;
  }
  .pcss3t-theme-3 > input:checked + label {
    background: #d9d92b;
    color: #000;
    opacity: 1;
  }
  .pcss3t-theme-3 > ul {
    border-top: 4px solid #d9d92b;
    border-bottom: 4px solid #d9d92b;
    border-radius: 0;
    box-shadow: none;
  }


  /**/
  /* theme 4 */
  /**/
  .pcss3t-theme-4 > label {
    margin: 0 10px 10px 0;
    border-radius: 5px;
    background: #78c5fd;
    background: linear-gradient(to bottom, #78c5fd 0%, #2c8fdd 100%);
    background: -o-linear-gradient(top, #78c5fd 0%, #2c8fdd 100%);
    background: -ms-linear-gradient(top, #78c5fd 0%, #2c8fdd 100%);
    background: -moz-linear-gradient(top, #78c5fd 0%, #2c8fdd 100%);
    background: -webkit-linear-gradient(top, #78c5fd 0%, #2c8fdd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#78c5fd', endColorstr='#2c8fdd', GradientType=0);
    box-shadow: inset 0 1px rgba(255,255,255,0.5), 0 1px rgba(0,0,0,0.5);
    line-height: 39px;
    text-shadow: 0 1px rgba(0,0,0,0.5);
    color: #fff;
  }
  .pcss3t-theme-4 > label:hover {
    background: #90cffc;
    background: linear-gradient(to bottom, #90cffc 0%, #439bde 100%);
    background: -o-linear-gradient(top, #90cffc 0%, #439bde 100%);
    background: -ms-linear-gradient(top, #90cffc 0%, #439bde 100%);
    background: -moz-linear-gradient(top, #90cffc 0%, #439bde 100%);
    background: -webkit-linear-gradient(top, #90cffc 0%, #439bde 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#90cffc', endColorstr='#439bde', GradientType=0);
  }
  .pcss3t-theme-4 > input:checked + label {
    top: 1px;
    background: #5f9dc9;
    background: linear-gradient(to bottom, #5f9dc9 0%, #2270ab 100%);
    background: -o-linear-gradient(top, #5f9dc9 0%, #2270ab 100%);
    background: -ms-linear-gradient(top, #5f9dc9 0%, #2270ab 100%);
    background: -moz-linear-gradient(top, #5f9dc9 0%, #2270ab 100%);
    background: -webkit-linear-gradient(top, #5f9dc9 0%, #2270ab 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5f9dc9', endColorstr='#2270ab', GradientType=0);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.5), 0 1px rgba(255,255,255,0.5);
    text-shadow: none;
  }
  .pcss3t-theme-4 > ul {
    border-radius: 5px;
    box-shadow: 0 2px 2px rgba(0,0,0,0.3);
  }
  @media screen and (max-width: 767px) {
    .pcss3t-theme-4 > label {
      margin-right: 0;
    }
  }


  /**/
  /* theme 5 */
  /**/
  .pcss3t-theme-5 {
    padding: 15px;
    border-radius: 5px;
    background: #ad6395;
    background: linear-gradient(to right, #ad6395 0%, #a163ad 100%);
    background: -o-linear-gradient(left, #ad6395 0%, #a163ad 100%);
    background: -ms-linear-gradient(left, #ad6395 0%, #a163ad 100%);
    background: -moz-linear-gradient(left, #ad6395 0%, #a163ad 100%);
    background: -webkit-linear-gradient(left, #ad6395 0%, #a163ad 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5f9dc9', endColorstr='#a163ad', GradientType=1);
  }
  .pcss3t-theme-5 > label {
    margin-right: 10px;
    margin-bottom: 15px;
    background: none;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    opacity: 1;
  }
  .pcss3t-theme-5 > label:hover {
    background: rgba(255,255,255,0.15);
  }
  .pcss3t-theme-5 > input:checked + label {
    background: rgba(255,255,255,0.3);
    color: #000;
  }
  .pcss3t-theme-5 > input:checked + label:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: 10px;
    margin-left: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
  }
  .pcss3t-theme-5 > ul {
    margin: 0 -15px -15px;
    border-radius: 0 0 5px 5px;
    box-shadow: none;
  }
  @media screen and (max-width: 767px) {
    .pcss3t-theme-5 > input:checked + label:after {
      display: none;
    }
  }

  .pcss3t > ul,
  .pcss3t > ul > li {
    height: 370px;
  }


}