.product-purchase{
  .container {

    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  h2 {
    font-size: 26px;
    margin: 20px 0;
    text-align: center;
    small {
      font-size: 0.5em;
    }
  }

  .responsive-table {
    margin: 0 !important;
    padding:0 !important;
    li {
      border-radius: 3px;
      padding: 25px 30px;
      display: flex;
      justify-content: space-between;

      margin-bottom: 25px;
    }
    .table-header {
      background-color: #68c268;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      font-family: "Lilita One";
      color:white;
    }
    .table-row {
      background-color: #ffffff;
      box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
    }
    .col-1 {
      flex-basis: 70%;
      align-items: center;
    }
    .col-2 {
      flex-basis: 10%;
      align-items: center;
    }
    .col-3 {
      flex-basis: 10%;
      align-items: center;
    }
    .col-4 {
      flex-basis: 10%;
      align-items: center;
    }

    @media all and (max-width: 1100px) {
      .table-header {
        display: none;
      }
      .table-row{

      }
      li {
        display: block;
      }
      .col {

        flex-basis: 100%;

      }
      .col {
        display: flex;
        padding: 10px 0;
        &:before {
          color: #6C7A89;
          padding-right: 40px;
          content: attr(data-label);
          flex-basis: 50%;
          /*text-align: right;*/
          font-weight: 600;
        }
      }
    }
  }
}