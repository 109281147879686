.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-wrapper > * {
  pointer-events: none;
}




/* CSS */
.button-59 {
  font-family: "Lilita One";
  align-items: center;
  background-color: white;
  border: 2px solid #000;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  font-size: 25px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  letter-spacing: -.8px;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;


}

.button-59:focus {
  color: #171e29;
}

.button-59:hover {
  border-color: #68c268;
  color: #68c268;
  fill: #68c268;
}

.button-59:active {
  border-color: #68c268;
  color: #68c268;
  fill: #68c268;
}

@media (min-width: 768px) {
  .button-59 {
    min-width: 170px;
  }
}