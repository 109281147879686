.success-page{
  font-family: "Lilita One";
  min-height: 85vh;
  text-align: center;
  padding: 40px 0;
  background: #EBF0F5;
  h1 {
    color: #88B04B;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
  }
  p {
    color: #404F5E;
    font-size:20px;
    margin: 0;
  }
  i {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left:-15px;
  }
  .container-card{
    margin-top: 50px;
  }
  .card {
    background: white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #C8D0D8;
    margin: 0 auto;
    max-width:500px;
  }
}