.product-section {
	font-family: "Montserrat", sans-serif !important;
	background: $primary-color;
	/*background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(160, 160, 160) 100%);*/
	height: auto;

	.all-card{
		padding-top: 15vh;
	}

	.title {
		margin-bottom: 35px;
		@media #{$sm} {
			margin-bottom:25px;
		}
	}

	.title h3 {
		color: #fff;
		display: inline-block;
		font-size: 30px;
		font-weight: 600;
		margin: 0;
		position: relative;
		vertical-align: top;
		z-index: 1;
		color: white;
	}

	.title h3:after {
		animation: slide-both-side 2s infinite both;
		background: $secundary-color;
		border-radius: 50%;
		content: "";
		height: 45px;
		left: -15px;
		position: absolute;
		top: -8px;
		width: 45px;
		z-index: -1;
		background: $secundary-color;
	}

	.title h1 {
		color: #fff;
		display: inline-block;

		font-weight: 600;
		margin: 0;
		position: relative;
		vertical-align: top;
		z-index: 1;
		color: white;
	}

	.title h1:after {
		animation: slide-both-side 2s infinite both;
		background: $secundary-color;
		border-radius: 50%;
		content: "";
		height: 45px;
		left: -15px;
		position: absolute;
		top: -8px;
		width: 45px;
		z-index: -1;
		background: $secundary-color;
	}



	.profile {
		font-family:'Lilita One';
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 15px;
		width: 100%;
		min-height: 380px;
		max-height: 380px;
		border-radius: 10px;
		background-color: #1b2028;
		position: relative;
		border: 3px solid transparent;
		background-clip: padding-box;
		text-align: center;
		color: #f1f3f3;
		background-image: linear-gradient(
										135deg,
										rgba(#407bfc, 0.35),
										rgba(#1b4aaf, 0.1) 15%,
										#1b2028 20%,
										#1b2028 100%
		);
		&:after {
			content: "";
			display: block;
			top: -3px;
			left: -3px;
			bottom: -3px;
			right: -3px;
			z-index: -1;
			position: absolute;
			border-radius: 16px;
			background-image: linear-gradient(
											135deg,
											#407bfc,
											#1b4aaf 20%,
											#1b2028 30%,
											#2c333e 100%
			);
		}
	}

	.profile-image {
		border-radius: 50%;
		overflow: hidden;
		width: 175px;
		height: 175px;
		position: relative;
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
		}
	}

	.profile-username {
		font-size: 1.5rem;
		font-weight: 600;
		margin-top: 1.5rem;
	}

	.profile-user-handle {
		color: #7d8396;
	}

	.profile-actions {
		margin-top: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		& > * {
			margin: 0 0.25rem;
		}
	}

	.btn {
		border: 0;
		background-color: transparent;
		padding: 0;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		line-height: 1;
		transition: 0.15s ease;

		&--primary {
			border-radius: 99em;
			background-color: #3772ff;
			background-image: linear-gradient(135deg, #5587ff, #3772ff);
			color: #fff;
			padding: 0 1.375em;
			&:hover,
			&:focus {
				background-size: 150%;
			}
		}

		&--icon {
			height: 46px;
			width: 46px;
			border-radius: 50%;
			border: 3px solid #343945;
			color: #7d8396;
			i {
				font-size: 1.25em;
			}

			&:hover,
			&:focus {
				border-color: #7d8396;
			}
		}
	}

	.profile-links {
		margin-top: 3.5rem;
	}

	.link {
		text-decoration: none;
		color: #7d8396;
		margin-left: 0.375rem;
		margin-right: 0.375rem;
		i {
			font-size: 1.25em;
		}
	}
	.card-container{
		position: relative;
	}

	.card-container .card{
		position: relative;
		width: 100%;
		min-height: 380px;
		max-height: 380px;
		background: #232323;
		border-radius: 20px;
		overflow: hidden;
	}

	.card-container .card:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #68c268;
		clip-path: circle(150px at 80% 20%);
		transition: 0.5s ease-in-out;
	}

	.card-container .card:hover:before{
		clip-path: circle(300px at 80% -20%);
	}

	.card-container .card:after{
		content: 'Protear';
		position: absolute;
		top: 30%;
		left: -20%;
		font-size: 12em;
		font-weight: 800;
		font-style: italic;
		color: rgba(129, 255, 113, 0.05)
	}

	.card-container .card .imgBx{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10000;
		width: 100%;
		height: 220px;
		transition: 0.5s;
	}

	.card-container .card:hover .imgBx{
		top: 0%;
		transform: translateY(0%);

	}

	.card-container .card .imgBx img{
		position: absolute;

	}

	.card-container .card .contentBx{
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 100px;
		text-align: center;
		transition: 1s;
		z-index: 10;
	}

	.card-container .card:hover .contentBx{
		height: 210px;
	}

	.card-container .card .contentBx h2{
		position: relative;
		font-weight: 600;
		letter-spacing: 1px;
		color: #fff;
		margin: 0;
	}

	.card-container .card .contentBx .size, .card-container .card .contentBx .color {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 8px 20px;
		transition: 0.5s;opacity: 0;
		visibility: hidden;
		padding-top: 0;
		padding-bottom: 0;
	}

	.card-container .card:hover .contentBx .size{
		opacity: 1;
		visibility: visible;
		transition-delay: 0.5s;
	}

	.card-container .card:hover .contentBx .color{
		opacity: 1;
		visibility: visible;
		transition-delay: 0.6s;
	}

	.card-container .card .contentBx .size h3, .card-container .card .contentBx .color h3{
		color: #fff;
		font-weight: 300;
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 2px;
		margin-right: 10px;
		font-family: "Lilita One";
	}

	.card-container .card .contentBx .size span{
		width: 26px;
		height: 26px;
		text-align: center;
		line-height: 26px;
		font-size: 14px;
		display: inline-block;
		color: #111;
		background: #fff;
		margin: 0 5px;
		transition: 0.5s;
		color: #111;
		border-radius: 4px;
		cursor: pointer;
	}

	.card-container .card .contentBx .size span:hover{
		background: #68c268;
	}

	.card-container .card .contentBx .color span{
		width: 20px;
		height: 20px;
		background: #ff0;
		border-radius: 50%;
		margin: 0 5px;
		cursor: pointer;
	}

	.card-container .card .contentBx .color span:nth-child(2){
		background: #9bdc28;
	}

	.card-container .card .contentBx .color span:nth-child(3){
		background: #03a9f4;
	}

	.card-container .card .contentBx .color span:nth-child(4){
		background: #e91e63;
	}

	.card-container .card .contentBx a{
		display: inline-block;
		padding: 10px 20px;
		background: #fff;
		border-radius: 4px;
		margin-top: 10px;
		text-decoration: none;
		font-weight: 600;
		color: #111;
		opacity: 0;
		transform: translateY(50px);
		transition: 0.5s;
		margin-top: 0;
		font-family: 'Lilita One';
		font-size: 13px;
	}

	.card-container .card:hover .contentBx a{
		opacity: 1;
		transform: translateY(0px);
		transition-delay: 0.75s;

	}


	@-webkit-keyframes slide-both-side {
		0% {
			-webkit-transform: translate(-5px);
			transform: translateY(-5px)
		}

		50% {
			-webkit-transform: translateY(5px);
			transform: translateY(5px)
		}

		to {
			-webkit-transform: translateY(-5px);
			transform: translateY(-5px)
		}
	}

	@keyframes slide-both-side {
		0% {
			-webkit-transform: translate(-5px);
			transform: translateY(-5px)
		}

		50% {
			-webkit-transform: translateY(5px);
			transform: translateY(5px)
		}

		to {
			-webkit-transform: translateY(-5px);
			transform: translateY(-5px)
		}
	}

	@media(max-width: 991px) {
		.title h3:after {
			left:0;
			top: -4px
		}
	}



}



