.navbar {

    background-color: $primary-color;
    width: 100%;
    top: 0px;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    position: fixed;
    z-index: 1001;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-backface-visibilite: hidden;
  }
  
  @media (min-width: 1300px) {
    .navbar .custome_container {
      max-width: 1440px;
    }
  }
  
  .navbar .logo_h {
    width: calc(1% + 100px); /* impostato a 50% + 10% della larghezza della viewport su desktop */
    margin-right: 0px;
  }
  
  .navbar .logo_h img {
    max-width: 100%;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  
  .navbar .logo_h img + img {
    display: none;
  }
  
  .navbar .navbar-nav .nav-item {
    margin-left: 35px;
  }
  
  .navbar .navbar-nav .nav-item .nav-link {
    @media #{$xl} {
      font-size: 14px !important;
    }
    @media #{$lg} {
      font-size: 14px !important;
    }
    @media #{$md} {
      font-size: 14px !important;
    }
    @media #{$lsm} {
      font-size: 14px !important;
    }
    @media #{$lsm} {
      font-size: 14px !important;
    }
    @media #{$tiny} {
      font-size: 14px !important;
    }
    @media #{$xtiny} {
      font-size: 14px !important;
    }
    font: 600 22px/30px "Lilita One", sans-serif;
    color: #ffff;
    @media #{$xsm} {
      color: white;
    }
   
    display: inline-block;
    text-transform: uppercase;
    position: relative;
    padding: 0px;
    cursor: pointer;
    transition: all 0.2s linear;
  }
  
  .navbar .navbar-nav .nav-item .nav-link:before {
    content: "";
    background-color: $third-color;
    border-radius: 30px;
    box-shadow: 0 0 20px $third-color;
    top: 0%;
    position: absolute;
    left: auto;
    right: 0px;
    bottom: 0px;
    width: 0%;
    height: 30.3px;
    z-index: 10;
    transform: skewY(357deg);
    -webkit-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
    -o-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
    transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  }
  
  .navbar .navbar-nav .nav-item .nav-link.active:before,
  .navbar .navbar-nav .nav-item .nav-link:hover:before {
    width: 100%;
    left: 0;
  }
  
  .navbar .navbar-nav.nav-right .nav-item {
    margin-left: 0px;
  }
  
  .navbar .login {
    padding: 5px 34px;
    font: 600 14px/30px "Montserrat", sans-serif;
    background: #fff;
    border-radius: 3px;
    color: #494949;
    text-transform: uppercase;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    position: relative;
    display: inline-block;
    border: 2px solid transparent;
    overflow: hidden;
    z-index: 1;
    position: relative;
    margin-top: 0px;
    display: inline-flex;
  }
  
  .navbar .login:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    border-radius: 0px;
    background: rgb(24, 147, 168);
    -webkit-transform-origin: 50%;
    -ms-transform-origin: 50%;
    transform-origin: 50%;
    transition-property: -webkit-transform;
    -webkit-transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  
  .navbar .login:hover {
    -webkit-box-shadow: 0px 16px 30px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 16px 30px 0px rgba(0, 0, 0, 0.12);
    color: #040c2c;
  }
  
  .navbar .login:hover:before {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
  
  #quicambiolanavbar
  .navbar_fixed .navbar {
    background: #000000;
    -webkit-box-shadow: 0px 2px 35px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 35px 0px rgba(0, 0, 0, 0.1);
    top: 0;
  }
  
  .navbar_fixed .logo_h img {
    display: none;
  }
  
  .navbar_fixed .logo_h img + img {
    display: inline-block;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  
  .navbar_fixed .navbar .navbar-nav .nav-item .nav-link {
    color: white;
  }
  
  .navbar_fixed .navbar-nav .nav-item .nav-link:before {
    background: #040c2c;
  }
  
  .navbar_fixed .login {
    border-color: #040c2c;
    color: #040c2c;
  }
  .navbar_fixed .login:before {
    background: #040c2c;
  }
  
  .navbar_fixed .login:hover {
    color: #fff;
  }
  
  .navbar-toggler {
    border: 0px;
    padding: 0px;
    border-radius: 0px;
    height: 80px;
    cursor: pointer;
  }
  
  .navbar-toggler span {
    display: block;
    width: 30px;
    height: 2px;
    background-image: -moz-linear-gradient(
      0deg,
      #ed3d82 0%,
      #f66264 75%,
      #fe8645 100%
    );
    background-image: -webkit-linear-gradient(
      0deg,
      #ed3d82 0%,
      #f66264 75%,
      #fe8645 100%
    );
    background-image: -ms-linear-gradient(
      0deg,
      #ed3d82 0%,
      #f66264 75%,
      #fe8645 100%
    );
    margin: auto;
    margin-bottom: 5px;
    -webkit-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    transition: all 400ms linear;
  }
  
  .navbar-toggler[aria-expanded="true"] span:first-child {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    top: 6px;
  }
  
  .navbar-toggler[aria-expanded="true"] span:nth-child(2) {
    opacity: 0;
  }
  
  .navbar-toggler[aria-expanded="true"] span:nth-child(3) {
    -webkit-transform: rotate(42deg);
    -ms-transform: rotate(42deg);
    transform: rotate(42deg);
    bottom: 8px;
    position: relative;
  }
  
  .navbar-toggler:focus {
    outline: none;
  }
  
  .dark_menu .navbar-nav .nav-item .nav-link {
    color: #040c2c;
  }
  .dark_menu .navbar-nav .nav-item .nav-link.active:before,
  .dark_menu .navbar .navbar-nav .nav-item .nav-link:hover:before {
    background: #040c2c;
  }
  .dark_menu .login {
    background: #040c2c;
    color: #fff;
  }
  .dark_menu .logo_h img {
    display: none;
  }
  .dark_menu .logo_h img + img {
    display: block;
  }