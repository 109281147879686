.contact-section{
  padding-bottom: 0 !important;
  padding: 120px 0px;
  h4 {
    font-family: "Montserrat", sans-serif;
    color: white;
    text-transform: capitalize;
    padding-bottom: 24px;
  }
  .contact-nav{
    margin-top:3vh;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    color:white;
    font-family: "Montserrat", sans-serif !important;
    .item{

    }
  }
  .title {
    margin-bottom: 35px;
    @media #{$sm} {
      margin-bottom:25px;
    }
  }


  .title h3 {
    color: #fff;
    display: inline-block;
    font-size: 30px;
    font-weight: 600;
    margin: 0;
    position: relative;
    vertical-align: top;
    z-index: 1;
    color: white;
  }

  .title h3:after {
    animation: slide-both-side 2s infinite both;
    background: $secundary-color;
    border-radius: 50%;
    content: "";
    height: 45px;
    left: -15px;
    position: absolute;
    top: -8px;
    width: 45px;
    z-index: -1;
    background: $secundary-color;
  }

  @-webkit-keyframes slide-both-side {
    0% {
      -webkit-transform: translate(-5px);
      transform: translateY(-5px)
    }

    50% {
      -webkit-transform: translateY(5px);
      transform: translateY(5px)
    }

    to {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px)
    }
  }

  @keyframes slide-both-side {
    0% {
      -webkit-transform: translate(-5px);
      transform: translateY(-5px)
    }

    50% {
      -webkit-transform: translateY(5px);
      transform: translateY(5px)
    }

    to {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px)
    }
  }

  @media(max-width: 991px) {
    .title h3:after {
      left:0;
      top: -4px
    }
  }

  .input_form form .form-control {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #494949;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ededed;
    border-radius: 0px;
    padding-left: 20px;
    text-transform: capitalize;
    min-height: 44px;
    margin-bottom: 30px;
  }
  .input_form form .form-control.placeholder {
    color: #797979;
  }
  .input_form form .form-control:-moz-placeholder {
    color: #797979;
  }
  .input_form form .form-control::-moz-placeholder {
    color: #797979;
  }
  .input_form form .form-control::-webkit-input-placeholder {
    color: #797979;
  }
  .input_form form .form-control.error {
    margin-bottom: 0px;
  }
  .input_form form .form-control.error + .error {
    font: 400 10px/30px "Montserrat", sans-serif;
    color: #494949;
    background: none;
    border: 0px;
    margin-bottom: 0px;
    display: block;
  }
  .input_form form textarea.form-control {
    resize: none;
    height: 142px;
    padding-top: 12px;
  }
  .input_form form .send_btn {
    font-size: 16px;
    border-radius: 4px;
    color: #040c2c;
    padding: 14px 38px 14px 42px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    background: transparent;
    box-shadow: none;
  }
  .input_form form .send_btn:hover {
    color: #fff;
  }

  .input_form form {
    .button-19 {
      appearance: button;
      background-color: $secundary-color;
      border: solid transparent;
      border-radius: 16px;
      border-width: 0 0 4px;
      box-sizing: border-box;
      color: #FFFFFF;
      cursor: pointer;
      display: inline-block;
      font-family: din-round,sans-serif;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: .8px;
      line-height: 20px;
      margin: 0;
      outline: none;
      overflow: visible;
      padding: 13px 16px;
      text-align: center;
      text-transform: uppercase;
      touch-action: manipulation;
      transform: translateZ(0);
      transition: filter .2s;
      user-select: none;
      -webkit-user-select: none;
      vertical-align: middle;
      white-space: nowrap;
      width: 100%;
    }

    .button-19:after {
      background-clip: padding-box;
      background-color: $secundary-color;
      border: solid transparent;
      border-radius: 16px;
      border-width: 0 0 4px;
      bottom: -4px;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }

    .button-19:main,
    .button-19:focus {
      user-select: auto;
    }

    .button-19:hover:not(:disabled) {
      filter: brightness(1.1);
      -webkit-filter: brightness(1.1);
    }

    .button-19:disabled {
      cursor: auto;
    }
  }
  .input_form #success,
  .contact-section .input_form #error {
    position: absolute;
    top: -15px;
    display: block;
    right: 0;
    left: 0;
    bottom: -15px;
    background: rgba(0, 0, 0, 0.5);
    display: none;
  }
  .input_form #success {
    color: lawngreen;
  }
  .input_form #error {
    color: orangered;
  }
  .contact_info {
    padding-left: 50px;
  }

  .contact_info ul {
    display: block;
    padding-right: 60px;
  }

  .contact_info ul .item {
    cursor: pointer;
    display: block;
  }

  .contact_info ul .item .media i {
    color: #494949;
    line-height: 28px;
    text-align: center;
    font-size: 18px;
    padding-right: 10px;
  }

  .contact_info ul .item .media .media-body {
    vertical-align: middle;
  }

  .contact_info ul .item .media .media-body a {
    color: #797979;
    font: 400 16px/28px "Montserrat", sans-serif;
  }

  .contact_info ul .item + .item {
    padding-top: 15px;
  }



  /*-------------------------[ MFFLE INPUT ]-------------------------------------------------------*/
  input {
    outline: none;
    border: none;
  }

  textarea {
    outline: none;
    border: none;
  }

  textarea:focus, input:focus {
    border-color: transparent !important;
  }
  input:focus::-webkit-input-placeholder { color:transparent; }
  input:focus:-moz-placeholder { color:transparent; }
  input:focus::-moz-placeholder { color:transparent; }
  input:focus:-ms-input-placeholder { color:transparent; }

  textarea:focus::-webkit-input-placeholder { color:transparent; }
  textarea:focus:-moz-placeholder { color:transparent; }
  textarea:focus::-moz-placeholder { color:transparent; }
  textarea:focus:-ms-input-placeholder { color:transparent; }

  input::-webkit-input-placeholder { color: #fff;}
  input:-moz-placeholder { color: #fff;}
  input::-moz-placeholder { color: #fff;}
  input:-ms-input-placeholder { color: #fff;}

  textarea::-webkit-input-placeholder { color: #fff;}
  textarea:-moz-placeholder { color: #fff;}
  textarea::-moz-placeholder { color: #fff;}
  textarea:-ms-input-placeholder { color: #fff;}

  .wrap-maffle-input {
    font-family: "Montserrat", sans-serif;
    width: 100%;
    position: relative;
    border-bottom: 2px solid rgba(104,194,104,0.50);
    margin-bottom: 30px;
  }
  .wrap-maffle-input-descrizione {
    font-family: "Montserrat", sans-serif;
    width: 100%;
    position: relative;
    border-bottom: 2px solid rgba(104,194,104,0.50);
    margin-bottom: 30px;
    min-height: 200px;
    height: auto;
  }

  .maffle-input {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: #fff;


    display: inline-table;
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0 5px 0 38px;

  }
  .maffle-input-text {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: #fff;
    display: block;
    width: 100%;
    height: 250px;
    background: transparent;
    padding: 0 5px 0 38px;

  }

  .focus-maffle-input {
    font-family: "Montserrat", sans-serif;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  .focus-maffle-input::before {
    font-family: "Montserrat", sans-serif;
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;

    background: $secundary-color;
  }

  .focus-maffle-input::after {
    font-family: 'ElegantIcons';
    font-size: 22px;
    color: #fff;

    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 6px;
    left: 0px;
    padding-left: 5px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }

  .maffle-input:focus {
    padding-left: 5px;
  }

  .maffle-input:focus + .focus-maffle-input::after {
    top: -22px;
    font-size: 18px;
  }

  .maffle-input:focus + .focus-maffle-input::before {
    width: 100%;
  }

  .has-val.maffle-input + .focus-maffle-input::after {
    top: -22px;
    font-size: 18px;
  }

  .has-val.maffle-input + .focus-maffle-input::before {
    width: 100%;
  }

  .has-val.maffle-input {
    padding-left: 5px;
  }

}





.contact-section .input_form #success p,
.contact-section .input_form #error p {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
}


